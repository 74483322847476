import {
  PrintArtwork,
  CanvasArtwork,
  DibondArtwork,
  FramedArtwork,
} from "starboard/dist/models/configuration";

export enum ArtworkImageSource {
  METAFIELD = "METAFIELD",
  FIRST_IMAGE = "FIRST_IMAGE",
}

export enum RuleType {
  EXACT_MATCH = "EXACT_MATCH",
  ARTWORK_SIZE = "ARTWORK_SIZE",
}

export interface ESRule {
  id: string;
  variantOptionName: string;
  ruleType: RuleType;
  term?: string;
  artworkConfig?: PrintArtwork | CanvasArtwork | DibondArtwork | FramedArtwork;
}

export interface ESConfig {
  scene: any;
  allProducts: boolean | undefined;
  productTypes: string;
  artworkImageSource: ArtworkImageSource;
  defaultArtworkConfig:
    | PrintArtwork
    | CanvasArtwork
    | DibondArtwork
    | FramedArtwork;
  rules: ESRule[];
}
