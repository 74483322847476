import React, { useEffect, useState } from "react";

import { v4 as uuidv4 } from "uuid";

// import "./style.css";

import "@shopify/polaris/build/esm/styles.css";
import {
  Icon,
  LegacyStack as Stack,
  LegacyCard as Card,
  // Checkbox,
  Text,
  Page,
  Button,
  RadioButton,
  Modal,
  PageActions,
  FormLayout,
  TextField,
  // Link,
  Checkbox,
  Banner,
  Popover,
  OptionList,
  // TextStyle,
} from "@shopify/polaris";
import { EditIcon, PlusIcon } from "@shopify/polaris-icons";

// import { ResourcePicker } from "@shopify/app-bridge-react";

//@ts-ignore
import { authenticatedFetch } from "./APIUtils.ts";

import { useFormik } from "formik";

//@ts-ignore
import MockupEditor from "./MockupEditor.tsx";
import { ContentMode } from "starboard";

//@ts-ignore
import AspectImage from "./AspectImage.tsx";

function EditRulesPage({
  app,
  subscriptionInfo,
  setEditRulesPageActive,
  setManageSubscriptionPageActive,
  didSaveRules,
  betaMode,
}) {
  //
  // Fetching collections
  //
  // const [abortController, setAbortController] = useState(new AbortController());
  const [isLoadingRules, setIsLoadingRules] = useState(true);
  const [rules, setRules] = useState<any>(null);
  const [unsavedRules, setUnsavedRules] = useState<any>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  // const [isLoadingProductTypeCount, setIsLoadingProductTypeCount] =
  //   useState(false);
  const [selectedCollections, setSelectedCollections] = useState([]);
  // const [matchingCollections, setMatchingCollections] = useState<any>([]);
  // const fetchMatchingCollections = async (productType) => {
  //   if (isLoadingProductTypeCount) {
  //     abortController.abort();
  //   }
  //   const ac = new AbortController();
  //   setAbortController(ac);
  //   setIsLoadingProductTypeCount(true);

  const [mockupEditorActiveTemplate, setMockupEditorActiveTemplate] =
    useState<any>(null);

  const [productMediaIndexPopoverActive, setProductMediaIndexPopoverActive] =
    useState(false);

  //   const response = await authenticatedFetch(app)(
  //     "/api/collections/search?search_term=" + productType,
  //     {
  //       method: "get",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       signal: ac.signal,
  //     }
  //   );
  //   const responseJSON = await response.json();
  //   // TODO: error handling

  //   setMatchingCollections(responseJSON);

  //   setIsLoadingProductTypeCount(false);
  // };
  // useEffect(() => {
  //   assessProductTypeCount("");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    // console.log("Fetching existing rules");
    const f = async () => {
      const response = await authenticatedFetch(app, "/api/mockups/rules", {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      let data = await response.json();

      setRules(data?.rules ?? {});
      setUnsavedRules(JSON.parse(JSON.stringify(data?.rules ?? {})));

      const collections = data?.rules?.collections ?? [];
      setSelectedCollections(collections);
      // formik.setFieldValue("collection_title", collection?.title);

      setIsLoadingRules(false);
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHasUnsavedChanges(
      JSON.stringify(unsavedRules) !== JSON.stringify(rules)
    );
  }, [rules, unsavedRules]);

  //
  // Selecting a collection
  //
  // TODO: why useCallback here?
  // const updateSelection = useCallback(
  //   (selected) => {
  //     const collection: any = matchingCollections.find(
  //       (c: any) => c.id === selected
  //     );

  //     setSelectedCollection(collection);

  //     formik.setFieldValue("collection_title", collection?.title);
  //   }, // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [matchingCollections]
  // );

  // const [showCollectionPicker, setShowCollectionPicker] = useState(false);

  //
  // Saving rules
  //
  const [isSavingRules, setIsSavingRules] = useState(false);
  const saveRules = async () => {
    // Set loading state
    setIsSavingRules(true);

    // // Prepare rules to submit
    // const rulesJSON = {
    //   automationEnabled: false, // TODO: remove this entirely I guess?
    //   collections: selectedCollections,
    // };

    // Authenticated fetch POST operation
    const response = await authenticatedFetch(app, "/api/mockups/rules", {
      method: "post",
      body: JSON.stringify(unsavedRules),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const responseJSON = await response.json();
    console.log(responseJSON);

    // Clear loading state
    setIsSavingRules(false);

    // "Did save rules" callback
    didSaveRules();

    // Dismiss modal
    setEditRulesPageActive(false);
  };

  // const optionsMarkup =
  //   matchingCollections.length > 0
  //     ? matchingCollections.map((option) => {
  //         const { title, id } = option;

  //         return (
  //           <Listbox.Option
  //             key={`${id}`}
  //             value={id}
  //             selected={selectedCollection === id}
  //             accessibilityLabel={title}
  //           >
  //             {title}
  //           </Listbox.Option>
  //         );
  //       })
  //     : null;
  // const loadingMarkup = isLoadingProductTypeCount ? (
  //   <Listbox.Loading accessibilityLabel="" />
  // ) : null;
  // const listboxMarkup =
  //   optionsMarkup || loadingMarkup ? (
  //     <Listbox onSelect={updateSelection}>
  //       {optionsMarkup && !isLoadingProductTypeCount ? optionsMarkup : null}
  //       {loadingMarkup}
  //     </Listbox>
  //   ) : null;

  const formik = useFormik({
    initialValues: {
      automation_enabled: true,
      collection_title: "",
      one_image: true,
    },
    // validate={(values) => {
    //   const errors = {};
    //   // if (values.collection_title === "") {
    //   //   errors.collection_title = "Required";
    //   // }

    //   // console.log(
    //   //   "Fetching product type filter count with term: " +
    //   //     values.collection_title
    //   // );
    //   // const result = await fetch(
    //   //   "/mockups/product-type-filter-count?product_type=" +
    //   //     values.collection_title,
    //   //   { method: "get" }
    //   // );
    //   // console.log("Result: " + (await result.json()));

    //   return errors;
    // }}
    onSubmit: (values, { setSubmitting }) => {
      // setTimeout(() => {
      //   alert(JSON.stringify(values, null, 2));
      //   setSubmitting(false);
      // }, 400);
    },
  });

  // const isInches =
  //   window.navigator.language === "en-US" || window.navigator.language === "my";
  const [inputField, setInputField] = useState<any>({
    width: 40,
    height: 60,
    lockAspectRatio: true,
    contentMode: ContentMode,
    style: "canvas",
    isBordered: false,
    frameMaterial: "black",
    edgeFinish: "white",
    frameMounted: false,
    sceneIndex: "blank-white-70",
    sceneIndices: ["blank-white-70"],
    linkToVariant: false,
  });

  // const totalProductCount = unsavedRules?.collections
  //   ?.map((c) => c.productsCount)
  //   .reduce((acc, count) => {
  //     return acc + count;
  //   }, 0);
  // console.log("totalProductCount: " + totalProductCount);
  // console.log(totalProductCount);

  return (
    <>
      {mockupEditorActiveTemplate && (
        <Modal
          open={mockupEditorActiveTemplate}
          title="Hello"
          titleHidden={true}
          size="large"
          noScroll
          // instant
          onClose={function (): void {
            setMockupEditorActiveTemplate(false);
          }}
        >
          {/* @ts-ignore */}
          {/* <KeepAlive> */}
          <MockupEditor
            app={app}
            shop={null}
            product={null}
            setMockupEditorActiveProduct={null}
            inputField={inputField}
            setInputField={setInputField}
            isInches={false}
            templateMode={true}
            allowLinkToVariant={
              !unsavedRules.templates?.find(
                (t) =>
                  t !== mockupEditorActiveTemplate && t.linkToVariant === true
              )
            }
            template={
              mockupEditorActiveTemplate
              // unsavedRules?.templates?.length > 0
              //   ? unsavedRules?.templates[0]
              //   : null
            }
            setMockupEditorActiveTemplate={setMockupEditorActiveTemplate}
            saveTemplateCallback={(config, thumbnail) => {
              console.log(config);

              // Upsert local rules state with the new template config and thumbnail image data
              if (!config.uuid) {
                console.log("No uuid...");
                config.uuid = uuidv4();
              }

              // console.log(config);
              setUnsavedRules((r) => {
                let templates = r.templates ?? [];

                let matchFound = false;
                console.log("new: " + config.uuid);
                templates.forEach((template) => {
                  console.log("existing: " + template.uuid);
                  if (template.uuid === config.uuid) {
                    for (let key in config) template[key] = config[key];
                    matchFound = true;
                  }
                });
                if (!matchFound) {
                  templates.push(config);
                }

                console.log("push:");
                console.log(config);

                return { ...r, templates: templates };
              });

              // Dismiss modal
              setMockupEditorActiveTemplate(null);
            }}
            deleteTemplateCallback={(config, thumbnail) => {
              // console.log(config);
              // Update local rules state to remove this template config
              if (config.uuid) {
                setUnsavedRules((r) => {
                  let templates = r.templates ?? [];

                  let matchingIndex = -1;
                  // console.log("new: " + config.uuid);
                  templates.forEach((template, index) => {
                    if (template.uuid === config.uuid) {
                      matchingIndex = index;
                    }
                  });
                  if (matchingIndex >= 0) {
                    templates.splice(matchingIndex, 1);
                  }

                  return { ...r, templates: templates };
                });
              }

              // Dismiss modal
              setMockupEditorActiveTemplate(null);
            }}
            betaMode={betaMode}
            imagePublishedCallback={undefined}
            manageSubscriptionCallback={undefined}
            watermark={undefined}
          />
          {/* </KeepAlive> */}
        </Modal>
      )}
      <Page
        backAction={{
          content: "Back",
          onAction: () => {
            setEditRulesPageActive(false);
          },
        }}
        title="Automation Settings"
        primaryAction={{
          content: "Save",
          disabled:
            isLoadingRules ||
            !hasUnsavedChanges ||
            !(
              unsavedRules?.templates?.length > 0 &&
              unsavedRules?.collections?.length > 0
            ),
          onAction: saveRules,
          loading: isSavingRules || isLoadingRules,
        }}
        secondaryActions={[
          {
            content: "Revert changes",
            disabled: isLoadingRules || !hasUnsavedChanges,
            onAction: () => {
              setUnsavedRules(JSON.parse(JSON.stringify(rules)));
            },
          },
        ]}
      >
        <form onSubmit={formik.handleSubmit}>
          {/* {Object.keys(rules).length === 0 && (
              <>
                <Banner status="info" title="Getting started">
                  <ul>
                    <li>Select at least one mockup (click the '+' below)</li>
                    <li>Select at least one collection</li>
                    <li>Click 'Save' at the top of this page</li>
                  </ul>
                  <p>
                    Mockups will start generating immediately. Once this has
                    completed you can review and publish the mockups to your
                    store.
                  </p>
                  <br />
                  <p>
                    {" "}
                    If you need any help setting this up for your store please{" "}
                    <Link url="mailto:support@frameup.app">contact us</Link>,
                    we're happy to help!
                  </p>
                </Banner>
                <br />
              </>
            )} */}
          <div
            style={{
              backgroundColor: "#EAF5FF",
            }}
          >
            {/* <Modal.Section>
              <Checkbox
                label="Enable automatic mockups"
                checked={formik.values.automation_enabled}
                onChange={(value) =>
                  formik.handleChange({
                    target: { id: "automation_enabled", value },
                  })
                }
                name="automation_enabled"
              />
            </Modal.Section> */}
          </div>
          <fieldset
            disabled={!formik.values.automation_enabled}
            style={{
              border: 0,
              opacity: formik.values.automation_enabled ? 1.0 : 0.5,
              margin: 0,
              padding: 0,
            }}
          >
            {/* <Banner title="What is Mockup Automation?" status="info">
                Mockup Automation is a service that automatically creates mockup
                images for your products based on rules you set here.
                <br />
                You’ll have a chance to review these mockup images before
                they’re published to your store.
              </Banner>
              <br /> */}
            <Card>
              <Card.Section>
                <Text variant="headingMd" as="h2">
                  What kind of mockup images do you want to create?
                </Text>
                <p>
                  <Text as="span" tone="subdued">
                    Define the mockups you want each product to have
                  </Text>
                </p>
                <br />

                {isLoadingRules ? (
                  <Stack spacing="tight">
                    <div className="HoverCell">
                      <AspectImage
                        imageSource={undefined}
                        dim={undefined}
                        plusBadge={undefined}
                        minusBadge={undefined}
                        successBadge={undefined}
                        renderInProgress={undefined}
                      />
                    </div>
                    <div className="HoverCell">
                      <AspectImage
                        imageSource={undefined}
                        dim={undefined}
                        plusBadge={undefined}
                        minusBadge={undefined}
                        successBadge={undefined}
                        renderInProgress={undefined}
                      />
                    </div>
                    <div className="HoverCell">
                      <AspectImage
                        imageSource={undefined}
                        dim={undefined}
                        plusBadge={undefined}
                        minusBadge={undefined}
                        successBadge={undefined}
                        renderInProgress={undefined}
                      />
                    </div>
                  </Stack>
                ) : (
                  <Stack spacing="tight" alignment="trailing">
                    {unsavedRules?.templates?.map((template) => (
                      <div
                        key={template.uuid}
                        className="HoverCell"
                        style={{
                          width: "6em",
                          height: "6em",
                        }}
                        onClick={() => {
                          setMockupEditorActiveTemplate(template);
                        }}
                      >
                        <AspectImage
                          imageSource={template.scene.backgroundThumbnail}
                          dim={undefined}
                          plusBadge={undefined}
                          minusBadge={undefined}
                          successBadge={undefined}
                          renderInProgress={undefined}
                        />
                        <div
                          style={{
                            position: "relative",
                            marginTop: "-3.7em",
                          }}
                          // src="./icons/job_indicator_in_progress.svg"
                          // alt=""
                        >
                          <Icon source={EditIcon} />
                        </div>
                      </div>
                    ))}
                    <div className="PlusCell">
                      <div
                        onClick={() => {
                          // setMockupEditorActiveProduct(product);
                          setMockupEditorActiveTemplate({});
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // backgroundColor: "rgba(0, 0, 0, 0.02)",
                          padding: 0,
                          borderRadius: 4,
                          borderWidth: 1,
                          borderStyle: "dashed",
                          borderColor: "#b5b5b5",
                          overflow: "hidden",
                          width: "6em",
                          height: "6em",
                        }}
                      >
                        <div
                          style={{
                            width: "1.25em",
                            height: "1.25em",
                            opacity: 0.7,
                          }}
                        >
                          <Icon source={PlusIcon}></Icon>
                        </div>
                      </div>
                    </div>
                    <Stack.Item fill></Stack.Item>
                    {!isLoadingRules &&
                      !(unsavedRules?.templates?.length > 0) && (
                        <Banner tone="critical">Add at least one mockup</Banner>
                      )}
                  </Stack>
                )}

                {/* {!isLoadingRules && !(unsavedRules?.templates?.length > 0) && (
                  <>
                    <br />
                    <Banner status="warning">Add at least one mockup</Banner>
                  </>
                )} */}
              </Card.Section>
            </Card>
            <Card>
              {/* <Card.Section> */}
              {/* <Heading>Automation Rules</Heading> */}
              {/* <p>
                    Configure how Mockup Automation works with your products
                  </p> */}

              {/* <div style={{ width: "25em", marginBottom: "0.5em" }}>
                    <Combobox
                      activator={
                        <Combobox.TextField
                          label="Create mockups for products in collection:"
                          value={formik.values.collection_title}
                          placeholder="Search collections"
                          onChange={async (value) => {
                            // Update search term and fetch collections
                            formik.handleChange({
                              target: { id: "collection_title", value },
                            });
                            setSelectedCollection(null);
                            await fetchMatchingCollections(value);
                          }}
                          onBlur={formik.handleBlur as any}
                          onFocus={async () => {
                            // Clear search term and fetch collections
                            formik.handleChange({
                              target: { id: "collection_title", value: "" },
                            });
                            setSelectedCollection(null);
                            await fetchMatchingCollections("");
                          }}
                          disabled={isLoadingRules}
                          autoComplete="off"
                          name="collection_title"
                        />
                      }
                    >
                      {listboxMarkup}
                    </Combobox>
                    {formik.errors.collection_title &&
                      formik.touched.collection_title &&
                      formik.errors.collection_title}
                  </div> */}

              {/* {selectedCollection !== null ? (
                    selectedCollection.productsCount ? (
                      selectedCollection.productsCount > 0 ? (
                        <Stack spacing="extraTight" alignment="center">
                          <Icon
                            source={CircleTickMinor as any}
                            color="success"
                          />
                          <TextStyle variation="subdued">
                            {selectedCollection.productsCount} products found
                          </TextStyle>
                        </Stack>
                      ) : (
                        <Stack spacing="extraTight" alignment="center">
                          <Icon source={AlertMinor as any} color="critical" />
                          <TextStyle variation="subdued">
                            No products found in collection
                          </TextStyle>
                        </Stack>
                      )
                    ) : (
                      <></>
                    )
                  ) : isLoadingProductTypeCount ? (
                    <Stack spacing="extraTight" alignment="center">
                      <Icon source={InfoMinor as any} color="subdued" />
                      <TextStyle variation="subdued">Checking...</TextStyle>
                    </Stack>
                  ) : (
                    <Stack spacing="extraTight" alignment="center">
                      <Icon source={InfoMinor as any} color="critical" />
                      <TextStyle variation="subdued">
                        No collection selected
                      </TextStyle>
                    </Stack>
                  )} */}

              {/* <br />
                <Checkbox
                  label="Only consider products that have one image"
                  checked={values.one_image}
                  onChange={(value) =>
                    handleChange({ target: { id: "one_image", value } })
                  }
                  name="one_image"
                /> */}
              {/* </Card.Section> */}
              <Card.Section>
                <Text variant="headingMd" as="h2">
                  Which products do you want to create mockups on?
                </Text>
                <p>
                  <Text as="span" tone="subdued">
                    Choose one or more collections
                  </Text>
                </p>

                <Stack vertical spacing="extraTight">
                  <Stack alignment="trailing">
                    <Stack.Item>
                      <br />
                      <Button
                        // plain
                        disabled={isLoadingRules}
                        // size="slim"
                        onClick={() => {
                          const f = async () => {
                            const selected = await window[
                              "shopify"
                            ].resourcePicker({
                              type: "collection",
                              selectionIds: selectedCollections as any,
                              action: "select",
                              multiple: true,
                            });
                            if (selected !== undefined) {
                              setSelectedCollections(selected as any);
                              setUnsavedRules((r) => {
                                return { ...r, collections: selected as any };
                              });
                            }
                          };
                          f();
                        }}
                      >
                        Select collections
                      </Button>
                    </Stack.Item>
                    <Stack.Item fill></Stack.Item>
                    {!isLoadingRules &&
                      !(unsavedRules?.collections?.length > 0) && (
                        <Banner tone="critical">
                          Select at least one collection
                        </Banner>
                      )}
                  </Stack>
                  <Stack.Item>
                    {unsavedRules?.collections?.length > 0 && (
                      <Stack vertical spacing="extraTight">
                        {unsavedRules?.collections?.map((collection) => (
                          <Stack.Item key={collection.id}>
                            {/* <Stack spacing="tight" alignment="center">
                              <div
                                style={{
                                  width: "1em",
                                  // backgroundColor: "red",
                                }}
                              >
                                <Icon
                                  source={CollectionsMajor}
                                  // color="subdued"
                                />
                              </div>
                              <div
                                style={{
                                  fontWeight: "500",
                                  // backgroundColor: "blue",
                                }}
                              >
                                {collection.title}
                              </div>
                            </Stack> */}

                            <Stack spacing="tight">
                              <div
                                style={{
                                  color: "#333538",
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: "7px",
                                  fontWeight: "500",
                                  marginLeft: "0.5em",
                                  marginTop: "0.3em",
                                }}
                              >
                                <img
                                  src="./icons/shopify_collections.svg"
                                  alt=""
                                />
                                <div>{collection.title}</div>
                                <div
                                  style={{
                                    color:
                                      collection.productsCount > 0
                                        ? "#949494"
                                        : "#cd5126",
                                    // display: "flex",
                                    // justifyContent: "center",

                                    fontSize: "9pt",
                                    fontWeight: "400",
                                    // marginLeft: "0.5em",
                                    // marginTop: "0.3em",
                                  }}
                                >
                                  {`${collection.productsCount}
                                    ${
                                      collection.productsCount === 1
                                        ? " product"
                                        : " products"
                                    }`}
                                </div>
                              </div>
                            </Stack>
                          </Stack.Item>
                        ))}
                      </Stack>
                    )}
                  </Stack.Item>
                  {/* <Stack.Item>
                      <div style={{ marginTop: "1.2em", color: "#6D6767" }}>
                        Your subscription supports automated mockups on up to{" "}
                        <div
                          style={{
                            fontWeight: "600",
                            display: "inline",
                          }}
                        >
                          {
                            subscriptionInfo?.productLimit ?? 10 // TODO: remove hardcoded fallback...
                          }{" "}
                          products
                        </div>
                        .{" "}
                        <Link
                          monochrome
                          onClick={() => {
                            setManageSubscriptionPageActive(true);
                            // setEditRulesPageActive(false);
                          }}
                        >
                          Upgrade to work with more products
                        </Link>
                      </div>
                    </Stack.Item> */}
                </Stack>
              </Card.Section>
              <Card.Section>
                {/* <p
                    style={{
                      fontWeight: 600,

                      marginBottom: "0.7em",
                    }}
                  >
                    
                  </p> */}
                <Checkbox
                  label="Only create mockups on active products"
                  onChange={(value) => {
                    setUnsavedRules((r) => {
                      return {
                        ...r,
                        skipNonActiveProducts: value,
                      };
                    });
                  }}
                  checked={unsavedRules?.skipNonActiveProducts ?? false}
                  disabled={isLoadingRules}
                />
                <br />
                <Checkbox
                  label="Exclude products that have more than one image"
                  onChange={(value) => {
                    setUnsavedRules((r) => {
                      return {
                        ...r,
                        skipProductsWithMoreThanOneImage: value,
                      };
                    });
                  }}
                  checked={
                    unsavedRules?.skipProductsWithMoreThanOneImage ?? false
                  }
                  disabled={isLoadingRules}
                />
              </Card.Section>
            </Card>
            <Card>
              <Card.Section>
                <Text variant="headingMd" as="h2">
                  How should we import each product?
                </Text>
                <p>
                  <Text as="span" tone="subdued">
                    Customize how we interpret your product data
                  </Text>
                </p>
                <p
                  style={{
                    fontWeight: 600,
                    marginTop: "1.6em",
                    marginBottom: "0.7em",
                  }}
                >
                  Artwork Image Source
                </p>

                {/* <OptionList
                    options={[
                      { value: "a", label: "First image in product media" },
                      { value: "b", label: "Artwork metafield" },
                    ]}
                    selected={[]}
                    onChange={function (selected: string[]): void {
                      throw new Error("Function not implemented.");
                    }}
                  ></OptionList> */}
                <Stack spacing="tight" vertical>
                  <Stack spacing="extraTight" vertical>
                    <RadioButton
                      label="Product media"
                      checked={unsavedRules?.artworkImageSource !== "METAFIELD"}
                      disabled={isLoadingRules}
                      id="disabled"
                      name="accounts"
                      // helpText="First product image should hold just the artwork with no frame or background"
                      onChange={(checked) => {
                        if (checked) {
                          setUnsavedRules((r) => {
                            return {
                              ...r,
                              artworkImageSource: "FIRST_IMAGE",
                            };
                          });
                        }
                      }}
                    />
                    <div style={{ marginLeft: "28px", marginTop: "-6px" }}>
                      <Text as="span" tone="subdued">
                        <Stack spacing="extraTight">
                          <Stack.Item>Use the product's</Stack.Item>
                          <div style={{ marginLeft: "3px", marginTop: "-1px" }}>
                            <Popover
                              active={productMediaIndexPopoverActive}
                              activator={
                                <Button
                                  onClick={() =>
                                    setProductMediaIndexPopoverActive(
                                      (state) => !state
                                    )
                                  }
                                  disabled={isLoadingRules}
                                  disclosure
                                  variant="plain"
                                >
                                  {unsavedRules?.artworkImageIndex === 0 ||
                                  unsavedRules?.artworkImageIndex === undefined
                                    ? "first"
                                    : unsavedRules?.artworkImageIndex === 1
                                    ? "second"
                                    : "last"}
                                </Button>
                              }
                              onClose={() =>
                                setProductMediaIndexPopoverActive(false)
                              }
                            >
                              <OptionList
                                allowMultiple={false}
                                onChange={(value) => {
                                  setUnsavedRules((r) => {
                                    return {
                                      ...r,
                                      artworkImageIndex: parseInt(value[0]),
                                    };
                                  });
                                  setProductMediaIndexPopoverActive(false);
                                }}
                                options={[
                                  {
                                    value: "0",
                                    label: "first",
                                    // active:
                                    //   !unsavedRules?.artworkImageIndex ||
                                    //   unsavedRules?.artworkImageIndex === 0,
                                  },
                                  {
                                    value: "1",
                                    label: "second",
                                    // active:
                                    //   unsavedRules?.artworkImageIndex === 1,
                                  },
                                  {
                                    value: "-1",
                                    label: "last",
                                    // active:
                                    //   unsavedRules?.artworkImageIndex === -1,
                                  },
                                ]}
                                selected={[
                                  `${unsavedRules?.artworkImageIndex ?? 0}`,
                                ]}
                              />
                            </Popover>
                          </div>
                          <Stack.Item>image as the artwork image</Stack.Item>
                        </Stack>
                      </Text>
                    </div>
                  </Stack>
                  <RadioButton
                    label="Metafield"
                    id="optional"
                    name="accounts"
                    helpText="Use the product's 'artwork.image' metafield as the artwork image"
                    // <TextStyle>
                    //   Please{" "}
                    //   <Button
                    //     plain
                    //     monochrome
                    //     url="mailto:support@frameup.app"
                    //   >
                    //     contact us
                    //   </Button>{" "}
                    //   for assistance using this option
                    // </TextStyle>
                    checked={unsavedRules?.artworkImageSource === "METAFIELD"}
                    disabled={isLoadingRules}
                    onChange={(checked) => {
                      if (checked) {
                        setUnsavedRules((r) => {
                          return { ...r, artworkImageSource: "METAFIELD" };
                        });
                      }
                    }}
                  />
                  {/* <Banner>
                      <Button plain monochrome url="mailto:support@frameup.app">
                        Contact us
                      </Button>{" "}
                      for help setting up your artwork images.
                    </Banner> */}
                </Stack>
              </Card.Section>
              <Card.Section>
                <p
                  style={{
                    fontWeight: 600,

                    marginBottom: "0.7em",
                  }}
                >
                  Default Artwork Size
                </p>
                <div style={{ maxWidth: "22em" }}>
                  <FormLayout>
                    <FormLayout.Group condensed>
                      <TextField
                        id="width"
                        label="Width"
                        type="number"
                        step={10}
                        min={10}
                        disabled={isLoadingRules}
                        onChange={(value) => {
                          setUnsavedRules((r) => {
                            return {
                              ...r,
                              defaultArtworkSize: {
                                ...r.defaultArtworkSize,
                                width: (parseInt(value) / 100).toFixed(2),
                              },
                            };
                          });
                        }}
                        value={(
                          (unsavedRules?.defaultArtworkSize?.width ?? 0.4) * 100
                        ).toString()}
                        autoComplete="off"
                        suffix="cm"
                      />
                      <TextField
                        id="height"
                        label="Height"
                        type="number"
                        step={10}
                        min={10}
                        disabled={isLoadingRules}
                        onChange={(value) => {
                          setUnsavedRules((r) => {
                            return {
                              ...r,
                              defaultArtworkSize: {
                                ...r.defaultArtworkSize,
                                height: (parseInt(value) / 100).toFixed(2),
                              },
                            };
                          });
                        }}
                        value={(
                          (unsavedRules?.defaultArtworkSize?.height ?? 0.6) *
                          100
                        ).toString()}
                        autoComplete="off"
                        suffix="cm"
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </div>
                <br />
                <p>
                  <Text as="span" tone="subdued">
                    Artwork size can be overridden using the ‘artwork.size’
                    product metafield. Example format: ‘40 x 60cm’{" "}
                  </Text>
                </p>
                <br />
                <Checkbox
                  label="Match image aspect ratio"
                  helpText="Override the size of the artwork to match the aspect ratio of the artwork image file"
                  onChange={(value) => {
                    setUnsavedRules((r) => {
                      return {
                        ...r,
                        matchImageAspectRatio: value,
                      };
                    });
                  }}
                  checked={unsavedRules?.matchImageAspectRatio ?? true}
                  disabled={isLoadingRules}
                />
                {/* <br />
                <Banner
                  title="Metafields"
                  action={{
                    content: "Contact Support",
                    url: "mailto:support@frameup.app",
                  }}
                  secondaryAction={{
                    content: "Learn about metafields",
                    url: "https://help.shopify.com/en/manual/custom-data/metafields",
                  }}
                >
                  Artwork metafields give you more customization power. Contact
                  us for help setting up artwork metafields, or anything else!
                </Banner> */}
              </Card.Section>
            </Card>
          </fieldset>
        </form>

        {/* {hasUnsavedChanges ? "Different" : "Match"}

        <Heading>Unsaved:</Heading>
        <TextStyle variation="code">{JSON.stringify(unsavedRules)}</TextStyle>

        <Heading>Saved:</Heading>
        <TextStyle variation="code">{JSON.stringify(rules)}</TextStyle>
        <br />
        <br /> */}
        <PageActions
          primaryAction={{
            content: "Save",
            disabled:
              isLoadingRules ||
              !hasUnsavedChanges ||
              !(
                unsavedRules?.templates?.length > 0 &&
                unsavedRules?.collections?.length > 0
              ),
            onAction: saveRules,
            loading: isSavingRules,
          }}
        />
        <br />
      </Page>
    </>
  );
}

export default EditRulesPage;
