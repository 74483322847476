import React, { useCallback } from "react";
import "@shopify/polaris/build/esm/styles.css";
import {
  Card,
  Button,
  RadioButton,
  TextField,
  Spinner,
  Select,
  Link,
  Icon,
  Page,
  Layout,
  Banner,
} from "@shopify/polaris";
import { SaveBar } from "@shopify/app-bridge-react";
import { useState, useMemo, useEffect } from "react";
import {
  AreaHeading,
  SectionHeading,
  SubSectionHeading,
} from "./components/Text";
import { SectionSeparator } from "./components/Separator";
import { RulesTable } from "./components/RulesTable";
import StyleSection from "./StyleSection";
import {
  ESConfig,
  ArtworkImageSource,
  RuleType,
  ESRule,
} from "./model/ESConfig";
import { arrayMove } from "react-movable";

import Collapsible from "react-collapsible";

//@ts-ignore
import { authenticatedFetch } from "./APIUtils.ts";

import deepCompare from "./helpers/deepCompare";
import ProductSearchBox from "./components/ProductSearchBox";
import {
  AlertTriangleIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@shopify/polaris-icons";
import { OnboardingCell } from "./components/OnboardingCell";

// TODO: consider a better way to store scenes...
const scenes = [
  {
    id: "art-gallery-2",
    name: "Minimal Art Gallery",
    backgroundTexture:
      "https://staging.starboard.frameup.app/textures/art-gallery-2_002.webp",
    backgroundThumbnail:
      "https://staging.starboard.frameup.app/textures/art-gallery-2_002_thumbnail.jpg",
    artworkPosition: {
      x: -0.331,
      y: -0.1,
      z: 0,
    },
    artworkRotation: { x: 0.0, y: 0.0, z: 0.0 },
    cameraPosition: {
      x: -0.0842329263687134,
      y: 0.0529343485832214 - 0.2,
      z: 5.0348901748657227,
    },
    cameraRotation: {
      x: 0.0000000370773421,
      y: -0.011013220064342,
      z: -0.0008112287614495,
    },
    cameraFov: 39.6,
  },
  {
    id: "dw-scene-02c_004",
    name: "Living Room",
    backgroundTexture:
      "https://staging.starboard.frameup.app/textures/dw-scene-02c_004.webp",
    backgroundThumbnail:
      "https://staging.starboard.frameup.app/textures/dw-scene-02c_004_thumbnail.jpg",
    cameraFov: 39.5978,
    focalColor: "#DACCBC",

    cameraPosition: {
      x: 0.9803496599197388,
      y: 1.1669756174087524,
      z: 0.4367722570896149,
    },
    cameraRotation: {
      x: -0.0000906325294636,
      y: 1.5734152793884277,
      z: -0.0000912726027309,
    },
    artworkPosition: {
      x: -4.4173040390014648,
      y: 1.6244051456451416,
      z: 0.608400821685791,
    },
    artworkRotation: { x: -0.0, y: 1.5707962512969971, z: 0.0 },
  },
  {
    id: "dw-scene-06",
    name: "High Chair",
    backgroundTexture:
      "https://staging.starboard.frameup.app/textures/dw-scene-06_001.webp",
    backgroundThumbnail:
      "https://staging.starboard.frameup.app/textures/dw-scene-06_001_thumbnail.jpg",
    cameraFov: 39.1462516608203899,
    cameraPosition: {
      x: 3.6428623199462891,
      y: 0.9349159002304077,
      z: -2.2904667854309082,
    },
    cameraRotation: { x: 0.0000000437113918, y: -0.0080207046121359, z: -0.0 },
    artworkPosition: {
      x: 3.6477346420288086,
      y: 1.2134265899658203,
      z: -5.2923445701599121,
    },
    artworkRotation: { x: 0.0, y: 0.0000001192092896, z: 0.0 },
  },
  {
    id: "dw-scene-02",
    name: "Cabinet",
    backgroundTexture:
      "https://staging.starboard.frameup.app/textures/dw-scene-07_001.webp",
    backgroundThumbnail:
      "https://staging.starboard.frameup.app/textures/dw-scene-07_001_thumbnail.jpg",
    cameraFov: 46.7704421144275102,
    cameraPosition: {
      x: 3.6428623199462891,
      y: 1.5132107734680176,
      z: -2.7225954532623291,
    },
    cameraRotation: { x: 0.0000000437113918, y: -0.0080207046121359, z: -0.0 },
    artworkPosition: {
      x: 3.6477346420288086,
      y: 1.6,
      z: -5.2923445701599121,
    },
    artworkRotation: { x: 0.0, y: 0.0000001192092896, z: 0.0 },
  },
  {
    id: "dw-scene-12",
    name: "Framing Studio",
    backgroundTexture:
      "https://staging.starboard.frameup.app/textures/dw-scene-12_001.webp",
    backgroundThumbnail:
      "https://staging.starboard.frameup.app/textures/dw-scene-12_001_thumbnail.jpg",

    cameraFov: 42.7412445386863666,
    cameraPosition: {
      x: 0.4593649804592133,
      y: 0.9295742511749268,
      z: 1.5617642402648926,
    },
    cameraRotation: { x: 0.0000000437113918, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: 0.4532622098922729,
      y: 1.3019938468933105,
      z: -1.4099138975143433,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "dw-scene-12v2",
    name: "Covered Sofa",
    backgroundTexture:
      "https://staging.starboard.frameup.app/textures/dw-scene-12v2_001.webp",
    backgroundThumbnail:
      "https://staging.starboard.frameup.app/textures/dw-scene-12v2_001_thumbnail.jpg",

    cameraFov: 42.7412445386863666,
    cameraPosition: {
      x: 0.9647351503372192,
      y: 0.9295742511749268,
      z: 2.6496372222900391,
    },
    cameraRotation: { x: 0.0000000437113918, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: 0.9586323499679565,
      y: 1.4383330345153809,
      z: -1.4099138975143433,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "dw-scene-14",
    name: "Minimal Bedroom",
    backgroundTexture:
      "https://staging.starboard.frameup.app/textures/dw-scene-14_001.webp",
    backgroundThumbnail:
      "https://staging.starboard.frameup.app/textures/dw-scene-14_001_thumbnail.jpg",

    cameraFov: 30.9559756542767666,
    cameraPosition: {
      x: 0.831526517868042,
      y: 0.9636288285255432,
      z: 2.8492748737335205,
    },
    cameraRotation: { x: 0.0000000437113918, y: -0.0, z: 0.0 },
    artworkPosition: {
      x: 0.8254237771034241,
      y: 1.4566757678985596,
      z: -1.4099138975143433,
    },
    artworkRotation: { x: 0.0, y: -0.0, z: 0.0 },
  },
  {
    id: "side-flower-1",
    name: "Side Table",
    backgroundTexture:
      "https://staging.starboard.frameup.app/textures/side-flower-1_002.webp",
    backgroundThumbnail:
      "https://staging.starboard.frameup.app/textures/side-flower-1_002_thumbnail.jpg",
    cameraPosition: {
      x: 0.0026348233222961,
      y: -0.1781124174594879,
      z: 3.1574902534484863,
    },
    cameraRotation: { x: 0.0000000437113883, y: -0.0, z: 0.0 },
    cameraFov: 31.6,
    artworkPosition: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    artworkRotation: { x: 0.0, y: 0.0, z: 0.0 },
  },
].sort((a: any, b: any) => {
  return a.name.localeCompare(b.name);
});

function AppEmbed({ app }) {
  const [unsavedEsConfig, setUnsavedEsConfig] = useState<ESConfig | null>();
  const [esConfig, setEsConfig] = useState<ESConfig | null>();

  const [shopify, setShopify] = useState<any>();

  const [previewProduct, setPreviewProduct] = useState<any>(null);
  const [previewVariantsSelection, setPreviewVariantsSelection] = useState<{
    [option: string]: string;
  }>({});

  useEffect(() => {
    loadConfig();

    setShopify(window["shopify"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [esPlaceholderDomNode, setEsPlaceholderDomNode] = useState(null);
  const onEsPlaceholderRefChange = useCallback((node) => {
    setEsPlaceholderDomNode(node); // trigger re-render on changes
  }, []);
  useEffect(() => {
    //
    // Load ES injector
    //
    if (esPlaceholderDomNode) {
      // Add CSS
      let styles = document.createElement("link");
      styles.rel = "stylesheet";
      styles.href = "/es_assets/index.css";
      document.getElementsByTagName("head")[0].appendChild(styles);
      // Add JS
      let script = document.createElement("script");
      script.type = "module";
      script.src = "/es_assets/client.js";
      document.getElementsByTagName("head")[0].appendChild(script);
      // When returning to this page we instruct client.js to attempt to reattach to starboard-container.
      const esReattach = window["es_reattach"];
      if (esReattach) esReattach();
    }
  }, [esPlaceholderDomNode]);

  useEffect(() => {
    if (deepCompare(unsavedEsConfig, esConfig)) {
      shopify?.saveBar.hide("my-save-bar");
    } else {
      shopify?.saveBar.show("my-save-bar");
    }
  }, [unsavedEsConfig, esConfig, shopify]);

  useEffect(() => {
    if (unsavedEsConfig && window["es_loadConfig"]) {
      window["es_loadConfig"]();
    }
  }, [unsavedEsConfig, previewProduct, previewVariantsSelection]);

  const modifyEsConfig = (value, fieldId) => {
    setUnsavedEsConfig(
      (prevEsConfig) =>
        ({
          ...prevEsConfig,
          [fieldId]: value,
        } as any)
    );
  };

  const modifyEsRuleOrder = (oldIndex, newIndex) => {
    setUnsavedEsConfig(
      (prevEsConfig) =>
        ({
          ...prevEsConfig,
          rules: arrayMove(prevEsConfig!.rules, oldIndex, newIndex),
        } as any)
    );
  };

  const newEsRule = () => {
    const newRuleId = crypto.randomUUID();
    setUnsavedEsConfig(
      (prevEsConfig) =>
        ({
          ...prevEsConfig,
          rules: [
            ...(prevEsConfig?.rules ?? []),
            {
              id: newRuleId,
              variantOptionName: "",
              ruleType: RuleType.EXACT_MATCH,
              term: "",
              artworkConfig: {
                kind: "default",
                edgeFinish: "default",
                frameMaterial: "default",
              } as any,
            } as ESRule,
          ],
        } as any)
    );
    return newRuleId;
  };
  const duplicateEsRule = (ruleId: string) => {
    const newRuleId = crypto.randomUUID();
    setUnsavedEsConfig((prevEsConfig) => {
      let rule = prevEsConfig?.rules.find((r) => r.id === ruleId);
      if (rule) {
        return {
          ...prevEsConfig,
          rules: [
            ...(prevEsConfig?.rules ?? []),
            {
              ...rule,
              id: newRuleId,
            } as ESRule,
          ],
        };
      }
      return prevEsConfig as any;
    });
    return newRuleId;
  };
  const deleteEsRule = (ruleId: string) => {
    setUnsavedEsConfig(
      (prevEsConfig) =>
        ({
          ...prevEsConfig,
          rules: prevEsConfig?.rules.filter((r) => r.id !== ruleId),
        } as any)
    );
  };

  const modifyEsRule = (ruleId: string, key: string, value: any) => {
    setUnsavedEsConfig((prevEsConfig) => {
      let newRules = prevEsConfig?.rules.map((r) => {
        if (r.id === ruleId) {
          return {
            ...r,
            [key]: value,
          };
        } else {
          return r;
        }
      });

      return {
        ...prevEsConfig,
        rules: newRules,
      } as any;
    });
    return;
  };

  const modifyEsRuleArtworkParameter = (
    ruleId: string,
    key: string,
    value: any
  ) => {
    setUnsavedEsConfig((prevEsConfig) => {
      let newRules = prevEsConfig?.rules.map((r) => {
        if (r.id === ruleId) {
          return {
            ...r,
            artworkConfig: {
              ...r.artworkConfig,
              [key]: value,
            },
          };
        } else {
          return r;
        }
      });

      return {
        ...prevEsConfig,
        rules: newRules,
      } as any;
    });
    return;
  };

  const inputField: any = useMemo(() => {
    return {
      ...unsavedEsConfig?.defaultArtworkConfig,
      style: unsavedEsConfig?.defaultArtworkConfig.kind,
      frameMounted:
        (unsavedEsConfig?.defaultArtworkConfig as any)?.isMounted ?? false,
    };
  }, [unsavedEsConfig]);

  useEffect(() => {
    const firstImage =
      previewProduct?.images?.length > 0 && previewProduct.images[0];

    let artworkConfig = {
      ...unsavedEsConfig?.defaultArtworkConfig,
      imagePath:
        firstImage?.transformedSrc ?? "/images/artwork-placeholder.png",
      aspectRatio: (firstImage?.width ?? 1) / (firstImage?.height ?? 1),
    };

    const starboard_liquid_data = {
      featuredImageAspectRatio: `${artworkConfig.aspectRatio}`,
      featuredImageSrc: artworkConfig.imagePath,
      productType: "",
      productTypeFilter: "",
      defaultVariant: "0", // Necessary! Should set variant ID
      variantToOptionValues: {
        "0": Object.entries(previewVariantsSelection).map((e) => {
          return { option: e[0], value: e[1] };
        }),
      },
      previewConfig: unsavedEsConfig,
    };
    window["starboard_liquid_data"] = starboard_liquid_data;
  }, [previewProduct, previewVariantsSelection, unsavedEsConfig]);

  const inputsHandler = (value, fieldId) => {
    // Remap weird input names from old frontend code
    if (fieldId === "style") {
      fieldId = "kind";
    } else if (fieldId === "frameMounted") {
      fieldId = "isMounted";
    }

    // Update default artwork config
    setUnsavedEsConfig(
      (prevEsConfig) =>
        ({
          ...prevEsConfig,
          defaultArtworkConfig: {
            ...prevEsConfig?.defaultArtworkConfig,
            [fieldId]: value,
          },
        } as any)
    );
  };

  const ruleCellProps = useMemo(() => {
    return unsavedEsConfig?.rules.map((rule) => {
      return {
        rule: rule,
        ruleId: rule.id,
        modifyEsRule: modifyEsRule,
        modifyEsRuleArtworkParameter: modifyEsRuleArtworkParameter,
        duplicateEsRule: duplicateEsRule,
        deleteEsRule: deleteEsRule,
        title: (
          <div className="text-ellipsis">
            If{" "}
            <b>
              {rule.variantOptionName?.length > 0 ? (
                rule.variantOptionName
              ) : (
                <div className="h-4 w-16 mx-0.5 inline-block">
                  <div className="w-full h-5/6 mt-1 rounded-sm bg-orange-100 "></div>
                </div>
              )}
            </b>
            {rule.ruleType === RuleType.EXACT_MATCH && (
              <>
                {" "}
                is exactly{" "}
                <b>
                  {rule.term?.length > 0 ? (
                    rule.term
                  ) : (
                    <div className="h-4 w-16 mx-0.5 inline-block">
                      <div className="w-full h-5/6 mt-1 rounded-sm bg-orange-100 "></div>
                    </div>
                  )}
                </b>
              </>
            )}
            {rule.ruleType === RuleType.ARTWORK_SIZE && (
              <>
                {" "}
                then <b>apply artwork size</b>
              </>
            )}
          </div>
        ),
      };
    });
  }, [unsavedEsConfig]);

  const loadConfig = async () => {
    // Authenticated fetch POST operation
    const response = await authenticatedFetch(app, "/api/appEmbed/config", {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
    const data = await response.json();

    let appEmbedConfig = data.appEmbedConfig;

    // Populate appEmbedConfig default properties as necessary
    // Note: These should be kept in sync with app-embed-public's defaults... probably worth refactoring this situation...
    if (!appEmbedConfig || !appEmbedConfig.defaultArtworkConfig) {
      // Onboarding completion hack. If appEmbedConfig (i.e. esConfig) is empty then reveal setup guide automatically.
      setSetupGuideOpen(true);

      appEmbedConfig = {
        productTypes: "",
        artworkImageSource: ArtworkImageSource.FIRST_IMAGE,
        defaultArtworkConfig: {
          kind: "framed",
          size: { x: 0.35, y: 0.5 },
          imagePath: "/images/artwork-placeholder.png",
          isMounted: true,
          isBordered: false,
          edgeFinish: "black",
          contentMode: "aspectScaleToFill",
          frameMaterial: "natural",
        } as any,
        rules: [
          {
            id: "f0d6b94e-8221-436f-836c-36a28d153fa6",
            term: "",
            ruleType: "ARTWORK_SIZE",
            artworkConfig: {
              kind: "default",
              edgeFinish: "default",
              frameMaterial: "default",
            },
            variantOptionName: "Size",
          },
        ],
      };
    } else {
      completeOnboardingStage(0);
      completeOnboardingStage(1);
      completeOnboardingStage(2);
    }
    // Set default scene (may be necessary even if appEmbedConfig was previously set as this parameter was added later)
    if (!appEmbedConfig.scene) {
      appEmbedConfig.scene = scenes.find((s) => {
        return s.id === "art-gallery-2";
      });
    }

    setUnsavedEsConfig(appEmbedConfig);
    setEsConfig(appEmbedConfig);

    shopify?.saveBar.hide("my-save-bar");
  };

  //
  // Saving rules
  //
  const [isSavingConfig, setIsSavingConfig] = useState(false);
  const saveConfig = async () => {
    // Set loading state
    setIsSavingConfig(true);

    // Authenticated fetch POST operation
    const response = await authenticatedFetch(app, "/api/appEmbed/config", {
      method: "post",
      body: JSON.stringify(unsavedEsConfig),
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log(response);
    const data = await response.json();
    // console.log(data);

    setEsConfig(data.appEmbedConfig);

    // Clear loading state
    setIsSavingConfig(false);

    // "Did save rules" callback
    // didSaveConfig();
  };

  const [hasWriteFileScope, setHasWriteFileScope] = useState(-1);
  useEffect(() => {
    const f = async () => {
      const scopes = (window["shopify"] as any)?.scopes;
      if (scopes) {
        const { granted } = await scopes.query();
        // console.log("scopes:");
        // console.log(granted);
        setHasWriteFileScope(granted.indexOf("write_files") > -1 ? 1 : 0);
      }
    };
    f();
  }, []);

  const [setupGuideOpen, setSetupGuideOpen] = useState(false);

  const [pulseElement, setPulseElement] = useState<string | null>(null);
  useEffect(() => {
    if (pulseElement) {
      setTimeout(() => {
        setPulseElement(null);
      }, 1500);
    }
  }, [pulseElement]);

  const [onboardingProgress, setOnboardingProgress] = useState([
    false, // Plugin Preview
    false, // Settings
    false, // Open theme editor
  ]);
  const completeOnboardingStage = useCallback(
    (completedStageIndex) => {
      setOnboardingProgress((s) =>
        s.map((e, i) => (i === completedStageIndex ? true : e))
      );
    },
    [setOnboardingProgress]
  );
  const onboardingStage = useMemo(() => {
    let highestCompletedStage = -1;
    for (let i = 0; i < onboardingProgress.length; i++) {
      if (onboardingProgress[i]) {
        highestCompletedStage = i;
      }
    }
    return highestCompletedStage + 1;
  }, [onboardingProgress]);
  const [onboardingFocus, setOnboardingFocus] = useState(0);

  // const [showPrerenderButton, setShowPrerenderButton] = useState(false);

  useEffect(() => {
    if (esConfig !== unsavedEsConfig) {
      completeOnboardingStage(1);
    }
  }, [esConfig, unsavedEsConfig, completeOnboardingStage]);

  return (
    <div className="flex flex-col items-center">
      {shopify ? (
        <SaveBar id="my-save-bar">
          <button
            variant="primary"
            onClick={() => saveConfig()}
            loading={(isSavingConfig ? "" : null) as any}
          ></button>
          <button
            onClick={() => {
              setUnsavedEsConfig(esConfig);
            }}
          ></button>
        </SaveBar>
      ) : (
        <>
          <Button
            variant="primary"
            onClick={() => saveConfig()}
            loading={(isSavingConfig ? "" : null) as any}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setUnsavedEsConfig(esConfig);
            }}
          >
            Discard
          </Button>
        </>
      )}
      <Page
        title="Live Preview Plugin"
        secondaryActions={[
          {
            content: "Contact Support",
            url: "mailto:support@frameup.app",
          },
        ]}
      >
        <Layout>
          {hasWriteFileScope === 0 && (
            <Layout.Section>
              <Banner
                tone="warning"
                title="Further permission required"
                action={{
                  content: "Grant permission",
                  onAction: () => {
                    const shop = shopify.config.shop;
                    const clientId = shopify.config.apiKey;
                    const successRedirect = `${document.location.origin}/api/auth/callback`;
                    window.open(
                      `https://${shop}/admin/oauth/authorize?client_id=${clientId}&scope=write_products,write_files&redirect_uri=${successRedirect}&state=00000`,
                      "_top"
                    );
                  },
                }}
              >
                Frame Up optimizes page load performance by saving plugin files
                to your Shopify store. These files are invisible to you and your
                customers and are automatically deleted when you uninstall the
                app.
              </Banner>
            </Layout.Section>
          )}
          <Layout.Section>
            <Card>
              <Collapsible
                transitionTime={120}
                open={setupGuideOpen}
                onTriggerOpening={() => setSetupGuideOpen(true)}
                onTriggerClosing={() => setSetupGuideOpen(false)}
                easing="ease-in-out"
                trigger={
                  <div className="flex justify-between items-center">
                    <AreaHeading>Setup guide</AreaHeading>
                    <div className="inline-block">
                      <Icon
                        source={
                          setupGuideOpen ? ChevronUpIcon : ChevronDownIcon
                        }
                      />
                    </div>
                  </div>
                }
              >
                <div className="mt-3 mb-5 font-semibold">
                  Use this guide to add Live Preview art-on-wall visualization
                  to your online store
                </div>
                <div className="mb-5 flex flex-col space-y-4 ml-4">
                  <OnboardingCell
                    currentTask={onboardingStage === 0}
                    complete={onboardingProgress[0]}
                  >
                    Try out the plugin with one of your products in{" "}
                    <button
                      className="underline"
                      onClick={() => {
                        setOnboardingFocus(1);
                        setPulseElement("plugin-preview");
                        document
                          .querySelector("#plugin-preview")
                          .scrollIntoView({
                            behavior: "smooth",
                          });
                      }}
                    >
                      Plugin Preview
                    </button>
                  </OnboardingCell>
                  <OnboardingCell
                    currentTask={onboardingStage === 1}
                    complete={onboardingProgress[1]}
                  >
                    Customize plugin in{" "}
                    <button
                      className="underline"
                      onClick={() => {
                        setOnboardingFocus(2);
                        setPulseElement("settings");
                        document.querySelector("#settings").scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      Advanced Settings
                    </button>{" "}
                    (optional)
                  </OnboardingCell>
                  <OnboardingCell
                    currentTask={onboardingStage === 1}
                    complete={onboardingProgress[2]}
                  >
                    <div className="flex items-start align-top flex-col space-y-2 sm:flex-row sm:items-center sm:space-x-3 sm:space-y-0">
                      <div className="">Enable plugin in the theme editor:</div>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setOnboardingFocus(3);
                          completeOnboardingStage(2);
                          if (shopify?.config) {
                            const shop = shopify.config.shop.split(".")[0];
                            let templateComponent = "";
                            let previewPathComponent = "";
                            if (previewProduct) {
                              templateComponent = `/${previewProduct.handle}`;
                              previewPathComponent = `&previewPath=%2Fproducts%2F${previewProduct.handle}`;
                            }
                            window.open(
                              `https://admin.shopify.com/store/${shop}/admin/themes/current/editor?template=product${templateComponent}&context=apps&activateAppId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_APP_ID}/live_preview${previewPathComponent}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        Open in theme editor
                      </Button>
                    </div>
                  </OnboardingCell>
                </div>
                <div className="">
                  If you experience any issues or have any questions, just send
                  us an email to{" "}
                  <a href="mailto:support@frameup.app" className="underline">
                    support@frameup.app
                  </a>
                  .
                </div>
              </Collapsible>
            </Card>
          </Layout.Section>
          <br />

          <Layout.Section>
            <div className="w-full flex justify-between space-y-4 flex-col sm:flex-row sm:space-x-4 sm:space-y-0">
              <div className="w-full sm:sticky self-start h-auto top-4">
                <Card>
                  <div
                    className={`p-5 -m-5 transition-colors duration-700 ${
                      pulseElement === "plugin-preview"
                        ? "bg-blue-200"
                        : "bg-inherit"
                    }`}
                    id="plugin-preview"
                  >
                    <div className="flex flex-row justify-between items-center mb-2">
                      <AreaHeading>Plugin Preview</AreaHeading>
                      {!setupGuideOpen && (
                        <Link
                          removeUnderline
                          // monochrome={!false}
                          onClick={() => {
                            completeOnboardingStage(2);
                            if (shopify?.config) {
                              const shop = shopify.config.shop.split(".")[0];
                              let templateComponent = "";
                              let previewPathComponent = "";
                              if (previewProduct) {
                                templateComponent = `/${previewProduct.handle}`;
                                previewPathComponent = `&previewPath=%2Fproducts%2F${previewProduct.handle}`;
                              }
                              window.open(
                                `https://admin.shopify.com/store/${shop}/admin/themes/current/editor?template=product${templateComponent}&context=apps&activateAppId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_APP_ID}/live_preview${previewPathComponent}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          Open in theme editor
                        </Link>
                      )}
                      {/* {showPrerenderButton ? (
                    <Button
                      onClick={() => {
                        const f = async () => {
                          // const response =
                          await authenticatedFetch(
                            app,
                            "/api/appEmbed/prerender",
                            {
                              method: "post",
                              headers: {
                                "Content-Type": "application/json",
                              },
                            }
                          );
                          // console.log(response);
                        };
                        f();
                      }}
                      variant="tertiary"
                    >
                      Pre-render
                    </Button>
                  ) : (
                    <div
                      className="w-5 h-5"
                      onClick={() => {
                        setShowPrerenderButton(true);
                      }}
                    ></div>
                  )} */}
                      {/* <Button
                    onClick={() => {
                      const jsonConfig = JSON.stringify({
                        ...window["starboard_liquid_data"],
                        showUI: false,
                      });
                      const uriEncodedConfig = encodeURIComponent(jsonConfig);
                      const testUrl = `https://starboard.frameup.app/#e=${uriEncodedConfig}`;
                      window.open(testUrl, "_blank");
                      // fetch(
                      //   `https://vvqb17wbo5.execute-api.us-east-1.amazonaws.com/dev/es-render?pageUrl=${`https://starboard.frameup.app/#e=${encodeURIComponent(
                      //     JSON.stringify({
                      //       ...window["starboard_liquid_data"],
                      //       showUI: false,
                      //     })
                      //   )}`}`,
                      //   {
                      //     method: "POST",
                      //     headers: {
                      //       "Content-Type": "application/x-www-form-urlencoded",
                      //     },
                      //   }
                      // );
                    }}
                  >
                    Render on cloud
                  </Button> */}
                      {/* <form
                    action={`https://vvqb17wbo5.execute-api.us-east-1.amazonaws.com/dev/es-render?pageUrl=${encodeURIComponent(
                      `https://starboard.frameup.app/#e=${encodeURIComponent(
                        JSON.stringify({
                          ...window["starboard_liquid_data"],
                          showUI: false,
                        })
                      )}`
                    )}`}
                    method="post"
                  >
                    <button
                      type="submit"
                      // onClick={() => {
                      //   const jsonConfig = JSON.stringify({
                      //     ...window["starboard_liquid_data"],
                      //     showUI: false,
                      //   });
                      //   const uriEncodedConfig = encodeURIComponent(jsonConfig);
                      //   const testUrl = `http://localhost:3006/#e=${uriEncodedConfig}`;
                      //   window.open(testUrl, "_blank");
                      // }}
                      variant="tertiary"
                    >
                      Debug pre-render
                    </button>
                  </form> */}
                    </div>
                    <div className="mb-3">
                      See how Live Preview will look on your website's product
                      pages by searching for one of your products:
                    </div>
                    <div className="mb-4">
                      <ProductSearchBox
                        app={app}
                        disabled={!unsavedEsConfig}
                        focusInput={onboardingFocus === 1}
                        selectedProduct={(product) => {
                          // console.log("setPreviewProduct");
                          // console.log(product);
                          completeOnboardingStage(0);
                          setPreviewProduct(product);
                          setPreviewVariantsSelection((pvs) => {
                            let defaultVariantsSelection = {};
                            // Iterate options found on the new product
                            for (const o of product.options) {
                              // Keep existing value if it exists on new product option, otherwise default to first value
                              if (
                                o.optionValues.findIndex(
                                  (ov) => ov.name === pvs[o.name]
                                ) < 0
                              ) {
                                defaultVariantsSelection[o.name] =
                                  o.optionValues[0].name;
                              } else {
                                defaultVariantsSelection[o.name] = pvs[o.name];
                              }
                            }
                            return defaultVariantsSelection;
                          });
                        }}
                      />
                    </div>
                    {previewProduct &&
                      unsavedEsConfig.productTypes?.length > 0 &&
                      unsavedEsConfig.productTypes
                        .toLowerCase()
                        .replace(/[\s]*,[\s]*/g, ",")
                        .split(",")
                        .filter((t) => t?.length > 0)
                        .indexOf(previewProduct.productType.toLowerCase()) ===
                        -1 && (
                        <div className="mb-4 flex flex-row space-x-1 opacity-80 justify-left bg-gray-200 p-2 rounded-md">
                          <div>
                            <Icon source={AlertTriangleIcon} />
                          </div>
                          <div>
                            {previewProduct.productType?.length > 0 ? (
                              !previewProduct.productType.includes(",") ? (
                                <>
                                  This product's type "
                                  <div className="inline-block font-semibold underline-offset-2">
                                    {previewProduct.productType}
                                  </div>
                                  " is not in the list of product types.{" "}
                                  <button
                                    className="inline-block text-blue-700 hover:underline"
                                    onClick={() => {
                                      modifyEsConfig(
                                        `${
                                          unsavedEsConfig.productTypes &&
                                          `${unsavedEsConfig.productTypes},`
                                        }${previewProduct.productType}`,
                                        "productTypes"
                                      );
                                    }}
                                  >
                                    Add product type
                                  </button>
                                </>
                              ) : (
                                <>
                                  This product has an unsupported product type:
                                  <div className="inline-block font-semibold underline-offset-2">
                                    {previewProduct.productType}
                                  </div>
                                  <br />
                                  <br />
                                  Frame Up doesn't support product types with
                                  commas in them.
                                </>
                              )
                            ) : (
                              <>
                                This product doesn't have a product type. Open
                                the product in the Shopify admin to set a
                                product type.
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    <div
                      className={`max-w-[35em] max-h-[35em] aspect-square border border-dashed border-gray-400 ${
                        !unsavedEsConfig ? "bg-gray-200" : "bg-white"
                      } ${previewProduct ? "h-full block" : "h-0 hidden"}`}
                    >
                      <div
                        className={`starboard-container h-full`}
                        ref={onEsPlaceholderRefChange}
                      >
                        <div className="flex flex-row h-full justify-center items-center space-x-2">
                          <Spinner size="small" />
                          Loading...
                        </div>
                      </div>
                    </div>
                    {!previewProduct && (
                      <>
                        No products?{" "}
                        <Link
                          // monochrome
                          removeUnderline
                          onClick={() => {
                            window.open(`shopify://admin/products/`);
                          }}
                        >
                          Manage your Shopify products
                        </Link>
                      </>
                    )}
                    {previewProduct?.options?.length > 0 && (
                      <div className="mt-5">
                        <div className="font-bold text-sm">Variants</div>
                        {previewProduct?.options?.map((v) => {
                          return (
                            <div className="mt-2" key={v.id}>
                              <div className="font-bold">{v.name}</div>
                              <div className="flex flex-row flex-wrap gap-1 mt-1">
                                {v.optionValues.map((ov) => {
                                  return (
                                    <div
                                      className={` rounded-lg p-1.5 text-xs font-semibold  border hover:bg-gray-50 hover:cursor-pointer ${
                                        previewVariantsSelection[v.name] ===
                                        ov.name
                                          ? "bg-white border-gray-400 text-black"
                                          : "bg-gray-100 text-gray-700"
                                      }`}
                                      onClick={() => {
                                        setPreviewVariantsSelection((s) => {
                                          return {
                                            ...s,
                                            [v.name]: ov.name,
                                          };
                                        });
                                      }}
                                      key={ov.id}
                                    >
                                      {ov.name}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                        <div className="mt-4">
                          <Link
                            // monochrome
                            removeUnderline
                            onClick={() => {
                              const productIdClean = previewProduct.id
                                .split("/")
                                .pop();
                              window.open(
                                `shopify://admin/products/${productIdClean}`
                              );
                            }}
                          >
                            Manage Shopify product
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
                {/* <br />
                <Banner
                  tone="warning"
                  title="Add or remove plugin from your store"
                  action={{
                    content: "Open theme editor",
                    onAction: () => {
                      if (shopify?.config) {
                        const shop = shopify.config.shop.split(".")[0];
                        let templateComponent = "";
                        let previewPathComponent = "";
                        if (previewProduct) {
                          templateComponent = `/${previewProduct.handle}`;
                          previewPathComponent = `&previewPath=%2Fproducts%2F${previewProduct.handle}`;
                        }
                        window.open(
                          `https://admin.shopify.com/store/${shop}/admin/themes/current/editor?template=product${templateComponent}&context=apps&activateAppId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_APP_ID}/live_preview${previewPathComponent}`,
                          "_blank"
                        );
                      }
                    },
                  }}
                >
                  Enable or disable the Live Preview app embed in the theme
                  editor:
                </Banner> */}
                {/* <br />
            <Card>
              <h1 className="text-base font-semibold mb-1">
                Add or remove plugin from online store
              </h1>
              <p className="mb-3">
                Enable or disable the Live Preview app embed from the theme
                editor.
              </p>
              <Button
                // variant="primary"
                onClick={() => {
                  const shop = shopify.config.shop.split(".")[0];
                  let templateComponent = "";
                  let previewPathComponent = "";
                  if (previewProduct) {
                    templateComponent = `/${previewProduct.handle}`;
                    previewPathComponent = `&previewPath=%2Fproducts%2F${previewProduct.handle}`;
                  }
                  window.open(
                    `https://admin.shopify.com/store/${shop}/admin/themes/current/editor?template=product${templateComponent}&context=apps&activateAppId=${process.env.REACT_APP_SHOPIFY_THEME_EXTENSION_APP_ID}/live_preview${previewPathComponent}`,
                    "_blank"
                  );
                }}
              >
                Open theme editor
              </Button>
            </Card> */}
              </div>
              <div
                className={`w-full ${
                  !setupGuideOpen || onboardingStage >= 1
                    ? "opacity-100 pointer-events-auto"
                    : "opacity-50 pointer-events-none"
                }`}
              >
                <Card>
                  <div
                    className={`p-5 -m-5 transition-colors duration-700 ${
                      pulseElement === "settings" ? "bg-blue-200" : "bg-inherit"
                    }`}
                    id="settings"
                  >
                    <AreaHeading className="mb-2">
                      Advanced Settings
                    </AreaHeading>
                    <div className="mb-4">
                      Optionally, customize the plugin based on your products
                      and store preferences.
                    </div>
                    {unsavedEsConfig ? (
                      <>
                        <div className="">
                          <div>
                            <SectionHeading className="mb-1">
                              Plugin Visibility
                            </SectionHeading>
                            <div className="mb-1">
                              Limit the Live Preview app embed to just your wall
                              art products by specifying your wall art{" "}
                              <Link
                                url="https://help.shopify.com/en/manual/products/details/product-type"
                                removeUnderline
                              >
                                product types
                              </Link>
                              :
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <div className="mt-1">
                              <TextField
                                label=""
                                value={unsavedEsConfig.productTypes}
                                placeholder="e.g. Art Prints, Photography"
                                onChange={(text) =>
                                  modifyEsConfig(text, "productTypes")
                                }
                                autoComplete="off"
                              />
                            </div>
                            <div className="mt-1 text-gray-500 italic">
                              Leave blank to enable for all products. Split
                              multiple types with{" "}
                              <div className="inline-block bg-gray-200 px-2 py-0.5 mx-1 font-bold rounded-sm">
                                ,
                              </div>
                            </div>
                          </div>
                        </div>
                        {false && (
                          <>
                            <SectionSeparator />
                            <div className="opacity-30">
                              <div>
                                <SectionHeading className="mb-1">
                                  Artwork image
                                </SectionHeading>
                                <div className="mb-1">
                                  Load the product's artwork image from this
                                  source:
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <RadioButton
                                  checked={
                                    unsavedEsConfig?.artworkImageSource ===
                                    ArtworkImageSource.FIRST_IMAGE
                                  }
                                  onChange={(state) => {
                                    if (state) {
                                      modifyEsConfig(
                                        ArtworkImageSource.FIRST_IMAGE,
                                        "artworkImageSource"
                                      );
                                    }
                                  }}
                                  label="Product media"
                                  helpText="Use the product's first image as the artwork image"
                                />
                                <RadioButton
                                  checked={
                                    unsavedEsConfig?.artworkImageSource ===
                                    ArtworkImageSource.METAFIELD
                                  }
                                  onChange={(state) => {
                                    if (state) {
                                      modifyEsConfig(
                                        ArtworkImageSource.METAFIELD,
                                        "artworkImageSource"
                                      );
                                    }
                                  }}
                                  label="Metafield"
                                  helpText="Use the product's 'artwork.image' metafield as the artwork image"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <SectionSeparator />
                        <div>
                          <div>
                            <SectionHeading className="mb-1">
                              Room View
                            </SectionHeading>
                            <div className="mb-2">
                              Select a virtual room for 'View in a room' mode:
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <Select
                              options={scenes.map((s) => {
                                return { label: s.name, value: s.id };
                              })}
                              onChange={(value) => {
                                modifyEsConfig(
                                  scenes.find((s) => {
                                    return s.id === value;
                                  }),
                                  "scene"
                                );
                                setTimeout(() => {
                                  const setCloseUpMode =
                                    window["es_setCloseUpMode"];
                                  if (setCloseUpMode) setCloseUpMode(false);
                                }, 200);
                              }}
                              value={unsavedEsConfig.scene?.id}
                              label={undefined}
                            />
                          </div>
                        </div>
                        <SectionSeparator />
                        <div>
                          <div>
                            <SectionHeading className="mb-1">
                              Presentation
                            </SectionHeading>
                            <div className="mb-3">
                              Present the artwork with these {/*size and */}
                              framing options:
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <SubSectionHeading className="mb-1">
                              Style
                            </SubSectionHeading>
                            <StyleSection
                              inputsHandler={inputsHandler}
                              inputField={inputField}
                              showTitle={false}
                            />
                          </div>
                        </div>
                        <SectionSeparator />
                        <div>
                          <div>
                            <SectionHeading className="mb-1">
                              Variant Rules
                            </SectionHeading>
                            <div className="mb-3">
                              Apply artwork size and frame settings
                              automatically based on your variants:
                            </div>
                          </div>
                          {ruleCellProps && (
                            <RulesTable
                              modifyEsRuleOrder={modifyEsRuleOrder}
                              newEsRule={newEsRule}
                              cells={ruleCellProps}
                            />
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-row h-auto py-2 justify-start items-top space-x-2">
                        <Spinner size="small" />
                        Loading...
                      </div>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </Layout.Section>
        </Layout>
      </Page>
      <br />
    </div>
    // <Page fullWidth>
    //   <br />
    //   <Layout>
    //     <Layout.Section>
    //       <Card>
    //         <Text variant="headingLg" as="h5">
    //           App embed
    //         </Text>
    //         <h1 className="text-3xl font-bold underline">Hello world!</h1>
    //       </Card>
    //     </Layout.Section>
    //   </Layout>
    // </Page>
  );
}

export default AppEmbed;
