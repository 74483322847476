import React, { useEffect, useState } from "react";

import {
  Banner,
  Modal,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
} from "@shopify/polaris";

//@ts-ignore
import { authenticatedFetch } from "./APIUtils.ts";

export default function SkippedProductsList({
  app,
  setEditRulesPageActive,
  setSkippedProductsListActive,
}) {
  // let a: number[] = [];
  // for (let i = 0; i < 20; i++) {
  //   a.push(i);
  // }

  const [isLoading, setIsLoading] = useState(true);
  const [skippedProducts, setSkippedProducts] = useState<any[]>([]);
  const [skippedProductsCount, setSkippedProductsCount] = useState();

  useEffect(() => {
    const f = async () => {
      setIsLoading(true);
      const responseRaw = await authenticatedFetch(
        app,
        "/api/mockups/skippedProducts",
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
        true
      );
      const response = await responseRaw.json();
      setSkippedProducts(response.remarks.skippedProducts);
      setSkippedProductsCount(response.remarks.skippedProductsCount);
      setIsLoading(false);
    };
    f();
  }, [app]);

  return (
    <Modal.Section>
      {isLoading ? (
        <>
          <SkeletonBodyText lines={1} />
          <br />
          <SkeletonDisplayText size="small" />
          <div style={{ width: "18em", marginTop: "1em" }}>
            <SkeletonBodyText lines={1} />
          </div>
          <br />
          <SkeletonDisplayText size="small" />
          <div style={{ width: "18em", marginTop: "1em" }}>
            <SkeletonBodyText lines={1} />
          </div>
          <br />
          <SkeletonDisplayText size="small" />
          <div style={{ width: "18em", marginTop: "1em" }}>
            <SkeletonBodyText lines={1} />
          </div>
        </>
      ) : (
        <>
          <Banner
            tone="info"
            title={`${skippedProductsCount} product${
              skippedProductsCount !== 1 ? "s" : ""
            } skipped from automation`}
            action={{
              content: "Automation Settings",
              onAction: () => {
                setEditRulesPageActive(true);
                setSkippedProductsListActive(false);
              },
            }}
            secondaryAction={{
              content: "Contact Support",
              url: "mailto:support@frameup.app",
            }}
          >
            If products are being skipped unexpectedly review your automation
            settings.
          </Banner>
          <br />
          {skippedProducts.map((skippedProduct) => (
            <>
              <Text as={"h6"} variant={"headingSm"}>
                {skippedProduct.title}
              </Text>
              {skippedProduct.reasons.map((reason) => (
                <p>‣ {reason}</p>
              ))}
              <br />
            </>
          ))}
        </>
      )}
    </Modal.Section>
  );
}
