import React from "react";

// import "./style.css";

import "@shopify/polaris/build/esm/styles.css";
import { LegacyStack as Stack, Button, Modal } from "@shopify/polaris";

function LearnAboutAutomationModal({
  setLearnAboutAutomationModalActive,
  setEditRulesPageActive,
}) {
  function BulletPoint({ text }) {
    return (
      <div style={{ fontSize: 17, color: "#404040", marginLeft: "0.3em" }}>
        <Stack spacing="tight" alignment="center">
          <img
            src="./icons/shopify_tick.svg"
            height="30"
            width="30"
            alt=""
            style={{ marginTop: "0.2em" }}
          />
          <Stack.Item>{text}</Stack.Item>
        </Stack>
      </div>
    );
  }
  return (
    <Modal
      open={true}
      title="Learn about automation"
      titleHidden={true}
      onClose={() => setLearnAboutAutomationModalActive(false)}
      size="large"
    >
      <Modal.Section>
        <div style={{ margin: "2em" }}>
          <Stack distribution="equalSpacing">
            <Stack.Item>
              <div>
                <br />
                <div
                  style={{ fontSize: 24, fontWeight: 500, color: "#404040" }}
                >
                  Create mockups automatically
                </div>
                <br />
                <BulletPoint text="Generate 1000s of mockups" />
                <BulletPoint text="Dozens of backdrop and framing options" />
                <BulletPoint text="Intelligent artwork size detection" />
                <BulletPoint text="One-click review and publish" />
                <br />
                <Button
                  variant="primary"
                  size="large"
                  onClick={() => {
                    setLearnAboutAutomationModalActive(false);
                    setEditRulesPageActive(true);
                  }}
                >
                  Get Started
                </Button>
                <br />
                <br />
                <div style={{ fontSize: 16, color: "#848484", width: "20em" }}>
                  Try for free with up to 10 products,
                  <br />
                  then hundreds from $49 / month.
                </div>
              </div>
            </Stack.Item>
            <Stack.Item>
              <img
                src="./images/automation_hero.jpg"
                width="350"
                height="350"
                style={{ borderRadius: "3em", backgroundColor: "#ddd" }}
                alt=""
              />
            </Stack.Item>
          </Stack>
        </div>
      </Modal.Section>
    </Modal>
  );
}

export default LearnAboutAutomationModal;
