import * as THREE from "three";
import { ContentMode } from "./models/configuration";

class ProductNode extends THREE.Group {
  baseNode: THREE.Group | undefined;
  artworkMaterial: THREE.MeshBasicMaterial | undefined;
  isBordered = true;
  flipShadow: boolean | undefined;
  rootUrl?: string;
  usePbrMaterials: boolean = false;

  public constructor() {
    super();
  }

  public async setup() {}

  // Can be overridden with custom resizing logic if needed
  public setDimensions(width: number, height: number) {
    this.scale.x = width;
    this.scale.y = height;
  }

  // Can be overridden to account for frames etc if needed
  public totalSize() {
    return new THREE.Vector2(this.scale.x, this.scale.y);
  }

  public applyTextureContentModeTransformAndBorderShaderModifier(
    texture: THREE.Texture,
    targetDimensions: THREE.Vector2,
    borderThickness: THREE.Vector2,
    contentMode: ContentMode
  ) {
    const sourceDimensions = new THREE.Vector2(
      texture.image.naturalWidth,
      texture.image.naturalHeight
    );

    const targetDimensionsAccountingForInnerBorder = new THREE.Vector2(
      targetDimensions.x - borderThickness.x * 2,
      targetDimensions.y - borderThickness.y * 2
    );
    let borderX = borderThickness.x / targetDimensions.x;
    let borderY = borderThickness.y / targetDimensions.y;

    const sourceAspectRatio = sourceDimensions.x / sourceDimensions.y;
    const borderedTargetAspectRatio =
      targetDimensionsAccountingForInnerBorder.x /
      targetDimensionsAccountingForInnerBorder.y;

    // Note: If aspect ratio is equal then we can leave scale at default of {1, 1}
    let xScale = 1.0;
    let yScale = 1.0;
    if (contentMode === ContentMode.AspectScaleToFit) {
      if (sourceAspectRatio > borderedTargetAspectRatio) {
        yScale = borderedTargetAspectRatio / sourceAspectRatio;
      } else if (sourceAspectRatio < borderedTargetAspectRatio) {
        xScale = sourceAspectRatio / borderedTargetAspectRatio;
      }
    } else {
      if (sourceAspectRatio > borderedTargetAspectRatio) {
        xScale = sourceAspectRatio / borderedTargetAspectRatio;
      } else if (sourceAspectRatio < borderedTargetAspectRatio) {
        yScale = borderedTargetAspectRatio / sourceAspectRatio;
      }
    }

    // Apply border with feathering effect to reduce aliasing
    // Note: Aliasing margin is hardcoded to a fixed distance which is not ideal when artwork is small in the scene...
    if (this.artworkMaterial) {
      if (borderX > 0 || borderY > 0) {
        this.artworkMaterial.onBeforeCompile = (shader) => {
          let token = "#include <map_fragment>";
          /*
        I'm considering each pixel.

        What I want:
        1. 5cm border around the edge of the texture.
        2. Artwork image scaledToFill within that area.

        Let's start with the 5cm border. We need to know what the real world dimensions of the mesh are. That's targetDimensions?

        This is how we get borderX and borderY.

        But the texture has ALREADY been stretched. This is the complexity.

        Two options:

        1. Reverse engineer the previous stretching, apply the border, then re-engineer the stretching (lol)
        2. Replace the existing UV stretching with a unified texture scaling solution in shader code

        It looks like option 2 is the only sensible approach.
        */

          // Note: This is not the proper way to insert parameters into GLSL but it does the job. Some hacks required to make it work, e.g. toFixed(5) to ensure numbers have a decimal place so they're treated as floating points in GLSL
          // Possible guide on the above: https://madebyevan.com/shaders/grid/
          let insert = /* glsl */ `
          float borderX = ${borderX.toFixed(5)};
          float borderY = ${borderY.toFixed(5)};
          float artworkScaleX = ${xScale.toFixed(5)};
          float artworkScaleY = ${yScale.toFixed(5)};
          vec2 pos = vMapUv;
          vec2 scale = vec2(1.0 / (1.0 - (borderX * 2.0)), 1.0/(1.0 - (borderY * 2.0)));
          vec2 relPos = ((pos * 2.0) - 1.0);
          vec2 scaledRelPos = relPos * scale;
          vec3 borderColor = vec3(1, 1, 1);
          if (scaledRelPos.x > 1.0 || scaledRelPos.x < -1.0 || scaledRelPos.y > 1.0 || scaledRelPos.y < -1.0) {
            diffuseColor = vec4(1, 1, 1, 1);
          } else {
            vec2 adjustedRelPos = scaledRelPos / vec2(artworkScaleX, artworkScaleY);
            float fade = 0.0;
            float featherScalar = 0.005;
            vec2 feather = vec2(featherScalar * artworkScaleX, featherScalar * artworkScaleY);
            if (abs(scaledRelPos.y) > 1.0 - feather.y) {
              fade += (feather.y - (1.0 - abs(scaledRelPos.y))) / feather.y;
            }
            if (abs(scaledRelPos.x) > 1.0 - feather.x) {
              fade += (feather.x - (1.0 - abs(scaledRelPos.x))) / feather.x;
            }
            vec4 col = texture2D(map, (adjustedRelPos + 1.0) / 2.0);
            diffuseColor = vec4(col.rgb * (1.0 - fade) + borderColor * fade, col.a);
          }
          `;

          shader.fragmentShader = shader.fragmentShader.replace(
            token,
            token + insert
          );
        };

        this.artworkMaterial.customProgramCacheKey = () => {
          return `${targetDimensions.x}_${targetDimensions.y}_${borderThickness.x}_${borderThickness.y}`;
        };

        texture.offset = new THREE.Vector2(0, 0);
        texture.repeat = new THREE.Vector2(1, 1);
      } else {
        // If no border then we revert to classic texture offset/repeat manipulation.
        // This is so we benefit from the built-in anti-aliasing.

        this.artworkMaterial.customProgramCacheKey = () => {
          return "NO_BORDER_CACHE_KEY";
        };

        texture.offset = new THREE.Vector2(
          (xScale - 1) / (2 * xScale),
          (yScale - 1) / (2 * yScale)
        );
        texture.repeat = new THREE.Vector2(1 / xScale, 1 / yScale);
      }

      this.artworkMaterial.needsUpdate = true;
    }
  }
}

export default ProductNode;
