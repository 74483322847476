import * as THREE from "three";
import { FrameMaterial } from "../models/configuration";

async function loadAsyncTexture(url: string, srgb = false) {
  const texture = await new THREE.TextureLoader().loadAsync(url);
  if (srgb) {
    texture.colorSpace = THREE.SRGBColorSpace;
  }

  // Set UV scaling
  const s = 4;
  texture.repeat.set(s, s);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  return texture;
}

export async function applyPreset(
  material: THREE.MeshStandardMaterial,
  preset: FrameMaterial,
  rootUrl: string
) {
  switch (preset) {
    case FrameMaterial.BlackWood: {
      material.color = new THREE.Color("#060606").convertLinearToSRGB();
      material.map = null;
      material.roughnessMap = null;
      material.metalnessMap = null;
      material.normalMap = null;
      material.metalness = 0.0;
      material.roughness = 0.4;
      break;
    }
    case FrameMaterial.WhiteWood: {
      material.color = new THREE.Color("#ccc").convertLinearToSRGB();
      material.map = null;
      material.roughnessMap = null;
      material.metalnessMap = null;
      material.normalMap = null;
      material.metalness = 0.0;
      material.roughness = 0.4;
      break;
    }
    case FrameMaterial.LightWood: {
      material.color = new THREE.Color("#ffffff").convertLinearToSRGB();
      material.map = await loadAsyncTexture(
        `${rootUrl}/textures/natural-wood-lighter.webp`,
        true
      );
      material.roughnessMap = null;
      material.metalnessMap = null;
      material.normalMap = null;
      material.metalness = 0.0;
      material.roughness = 0.4;
      break;
    }
    case FrameMaterial.DarkWood: {
      material.color = new THREE.Color("#C1A382").convertLinearToSRGB();
      material.map = await loadAsyncTexture(
        `${rootUrl}/textures/natural-wood-lighter.webp`,
        true
      );
      material.roughnessMap = null;
      material.metalnessMap = null;
      material.normalMap = null;
      material.metalness = 0.0;
      material.roughness = 0.4;
      break;
    }
    case FrameMaterial.Gold: {
      // material.color = new THREE.Color(
      //   "#c08d23"
      // ).convertLinearToSRGB();
      material.color = new THREE.Color("#fff").convertLinearToSRGB();

      material.map = await loadAsyncTexture(
        `${rootUrl}/textures/BrushedGold02_1K_BaseColor.webp`,
        true
      );

      material.metalnessMap = await loadAsyncTexture(
        `${rootUrl}/textures/BrushedGold02_1K_Metallic.webp`
      );
      material.roughnessMap = await loadAsyncTexture(
        `${rootUrl}/textures/BrushedGold02_1K_Roughness.webp`
      );
      material.normalMap = await loadAsyncTexture(
        `${rootUrl}/textures/BrushedGold02_1K_Normal.webp`
      );
      material.metalness = 1.0;
      material.roughness = 1.0;
      break;
    }
    case FrameMaterial.Silver: {
      // material.color = new THREE.Color(
      //   "#bbbdbd"
      // ).convertLinearToSRGB();
      material.color = new THREE.Color("#fff").convertLinearToSRGB();
      material.map = await loadAsyncTexture(
        `${rootUrl}/textures/BrushedSilver02_1K_BaseColor.webp`,
        true
      );
      material.metalnessMap = await loadAsyncTexture(
        `${rootUrl}/textures/BrushedGold02_1K_Metallic.webp`
      );
      material.roughnessMap = await loadAsyncTexture(
        `${rootUrl}/textures/BrushedGold02_1K_Roughness.webp`
      );
      material.normalMap = await loadAsyncTexture(
        `${rootUrl}/textures/BrushedGold02_1K_Normal.webp`
      );
      material.metalness = 1.0;
      material.roughness = 1.0;
      break;
    }
    case FrameMaterial.BlackMetallic: {
      material.color = new THREE.Color("#080808").convertLinearToSRGB();
      material.map = null;
      material.roughnessMap = null;
      material.metalnessMap = null;
      material.normalMap = null;
      material.metalness = 1.0;
      material.roughness = 0.08;
      break;
    }
    case FrameMaterial.WhiteMetallic: {
      material.color = new THREE.Color("#ffffff").convertLinearToSRGB();
      material.map = null;
      material.roughnessMap = null;
      material.metalnessMap = null;
      material.normalMap = null;
      material.metalness = 1.0;
      material.roughness = 0.2;
      break;
    }
    case FrameMaterial.RedMetallic: {
      material.color = new THREE.Color("#941a1c").convertLinearToSRGB();
      material.map = null;
      material.roughnessMap = null;
      material.metalnessMap = null;
      material.normalMap = null;
      material.metalness = 1.0;
      material.roughness = 0.08;
      break;
    }
    case FrameMaterial.BlueMetallic: {
      material.color = new THREE.Color("#a5c8df").convertLinearToSRGB();
      material.map = null;
      material.roughnessMap = null;
      material.metalnessMap = null;
      material.normalMap = null;
      material.metalness = 1.0;
      material.roughness = 0.08;
      break;
    }
    case FrameMaterial.LightestWoodGrain: {
      material.color = new THREE.Color("#ffffff").convertLinearToSRGB();
      material.map = await loadAsyncTexture(
        `${rootUrl}/textures/natural-wood-lightest.jpg`,
        true
      );
      material.roughnessMap = null;
      material.metalnessMap = null;
      material.normalMap = null;
      material.metalness = 0.0;
      material.roughness = 0.4;
      break;
    }
    case FrameMaterial.BlackWoodGrain: {
      material.color = new THREE.Color("#ffffff").convertLinearToSRGB();
      material.map = await loadAsyncTexture(
        `${rootUrl}/textures/natural-wood-black.jpg`,
        true
      );
      material.roughnessMap = null;
      material.metalnessMap = null;
      material.normalMap = null;
      material.metalness = 0.0;
      material.roughness = 0.4;
      break;
    }
    case FrameMaterial.GrayWoodGrain: {
      material.color = new THREE.Color("#ffffff").convertLinearToSRGB();
      material.map = await loadAsyncTexture(
        `${rootUrl}/textures/natural-wood-gray.jpg`,
        true
      );
      material.roughnessMap = null;
      material.metalnessMap = null;
      material.normalMap = null;
      material.metalness = 0.0;
      material.roughness = 0.4;
      break;
    }
    case FrameMaterial.WalnutWoodGrain: {
      material.color = new THREE.Color("#ffffff").convertLinearToSRGB();
      material.map = await loadAsyncTexture(
        `${rootUrl}/textures/natural-wood-walnut.jpg`,
        true
      );
      material.roughnessMap = null;
      material.metalnessMap = null;
      material.normalMap = null;
      material.metalness = 0.0;
      material.roughness = 0.4;
      break;
    }
  }

  material.needsUpdate = true;
}
