import * as THREE from "three";
import ProductNode from "./ProductNode";

class ImageNode extends ProductNode {
  material = new THREE.ShaderMaterial();

  public constructor() {
    super();
  }

  public async setup() {
    // TODO: could eventually accept Artwork (config) and do it's own set up accordingly
    //
    // Load canvas model
    //
    this.baseNode = new THREE.Group();

    /*
    function fragmentShader() {
      return `
      uniform vec2 realSize;
      uniform sampler2D frameTexture;
      uniform sampler2D artworkTexture;
      varying vec3 vUv;

      void main() {

        vec2 pos = vUv.xy+0.5;


        // vec2 frameImageSize = vec2(0.8, 0.6);
        // vec2 frameImageArtworkSize = vec2(0.7745, 0.725);

        vec2 frameImageSize = vec2(0.915, 0.915);
        vec2 frameImageArtworkSize = vec2(0.77, 0.76);

        // The output physical framed artwork dimensions, relative to those of the input reference frame image
        vec2 realRelSize = realSize / frameImageSize;

        vec2 cutout = frameImageArtworkSize;
        float startX = (1.0 - cutout.x)/2.0;
        float endX = 1.0-(1.0 - cutout.x)/2.0;
        float startY = (1.0 - cutout.y)/2.0;
        float endY = 1.0-(1.0 - cutout.y)/2.0;

        vec2 outPos = pos;
        vec2 posL = pos * realRelSize;
        vec2 posR = (pos * realRelSize) + (vec2(1.0, 1.0) - realRelSize);

        if (pos.x <= 0.5) {
          outPos.x = pos.x * realRelSize.x;
        } else {
          outPos.x = (pos.x * realRelSize.x) + (1.0 - realRelSize.x);
        }
        startX /= realRelSize.x;
        endX = (endX - (1.0 - realRelSize.x)) / realRelSize.x;

        if (pos.y <= 0.5) {
          outPos.y = pos.y * realRelSize.y;
        } else {
          outPos.y = (pos.y * realRelSize.y) + (1.0 - realRelSize.y);
        }
        startY /= realRelSize.y;
        endY = (endY - (1.0 - realRelSize.y)) / realRelSize.y;

        if (pos.x >= startX && pos.x <= endX && pos.y >= startY && pos.y <= endY) {
          vec2 artworkPos = vec2((pos.x - startX) / (endX - startX), (pos.y - startY) / (endY - startY));
          // gl_FragColor = vec4(artworkPos.x, artworkPos.y, 0, 1);
          gl_FragColor = texture2D(artworkTexture, artworkPos);
        } else {
          gl_FragColor = texture2D(frameTexture, outPos);
          // float p = 0.5;
          // if (length(pos) < 0.5) {
          //   p = 0.0;
          // } else if (length(pos) > 1.0) {
          //   p = 1.0;
          // }
          // gl_FragColor = mix(texture2D(frameTexture, posL), texture2D(frameTexture, posR), p);
        }


          // vec2 uv = outPos;

          // // per pixel partial derivatives
          // vec2 dx = dFdx(uv.xy);
          // vec2 dy = dFdy(uv.xy);

          // // rotated grid uv offsets
          // vec2 uvOffsets = vec2(0.125, 0.375);
          // vec2 offsetUV = vec2(0.0, 0.0);
          
          // offsetUV.xy = uv.xy + uvOffsets.x * dx + uvOffsets.y * dy;
          // gl_FragColor += texture2D(frameTexture, offsetUV);
          // offsetUV.xy = uv.xy - uvOffsets.x * dx - uvOffsets.y * dy;
          // gl_FragColor += texture2D(frameTexture, offsetUV);
          // offsetUV.xy = uv.xy + uvOffsets.y * dx - uvOffsets.x * dy;
          // gl_FragColor += texture2D(frameTexture, offsetUV);
          // offsetUV.xy = uv.xy - uvOffsets.y * dx + uvOffsets.x * dy;
          // gl_FragColor += texture2D(frameTexture, offsetUV);
          // gl_FragColor *= 0.25;

        

      }
  `;
    }

    function vertexShader() {
      return `
        varying vec3 vUv; 
    
        void main() {
          vUv = position; 
    
          vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
          gl_Position = projectionMatrix * modelViewPosition; 
        }
      `;
    }
    */

    let frameTexture = await new THREE.TextureLoader().loadAsync(
      "/images/girl.jpg"
    );
    let artworkTexture = await new THREE.TextureLoader().loadAsync(
      "/images/grid.png"
    );
    artworkTexture.anisotropy = 8;
    artworkTexture.minFilter = THREE.LinearMipmapLinearFilter;
    artworkTexture.magFilter = THREE.LinearFilter;
    let uniforms = {
      realSize: { type: "vec2", value: new THREE.Vector2(1, 1) },
      frameTexture: { type: "t", value: frameTexture },
      artworkTexture: { type: "t", value: artworkTexture },
    };
    this.material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      // fragmentShader: fragmentShader(),
      // vertexShader: vertexShader(),
    });
    this.material.dithering = true;

    let box = new THREE.Mesh(new THREE.BoxGeometry(), this.material);
    // this.artworkMaterial = box.material;

    this.baseNode.add(box);
    this.baseNode.position.z = 0.0005;
    this.baseNode.scale.z = 0.001;

    this.add(this.baseNode);
  }

  public setDimensions(width: number, height: number) {
    this.scale.x = width;
    this.scale.y = height;

    this.material.uniforms.realSize.value = this.scale;
  }
}

export default ImageNode;
