import React, { useEffect, useState } from "react";

// import "./style.css";

import "@shopify/polaris/build/esm/styles.css";
import {
  LegacyStack as Stack,
  LegacyCard as Card,

  // Checkbox,
  Spinner,
  Page,
  Button,

  // TextStyle,
} from "@shopify/polaris";

//@ts-ignore
import { authenticatedFetch } from "./APIUtils.ts";

function ManageSubscriptionPage({
  app,
  setManageSubscriptionPageActive,
  setSubscriptionInfo,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [currentSubscriptionTier, setCurrentSubscriptionTier] =
    useState<any>(null);
  const [postingTier, setPostingTier] = useState<any>(null);

  useEffect(() => {
    console.log("Fetching subscription tier");
    const f = async () => {
      await fetchSubscriptionTier();
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSubscriptionTier = async () => {
    setIsLoading(true);

    const response = await authenticatedFetch(
      app,
      "/api/settings/subscription",
      {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    );
    let data = await response.json();

    setCurrentSubscriptionTier(data?.subscriptionTier);

    setIsLoading(false);
  };

  const selectTier = async (tier) => {
    console.log(`selectTier: ${tier}`);

    try {
      setPostingTier(tier);

      // Update product on database with the new image URL
      const responseRaw = await authenticatedFetch(
        app,
        `/api/settings/selectSubscription`,
        {
          method: "POST",
          body: JSON.stringify({ tier: tier }),
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      );
      const response = await responseRaw.json();
      console.log("selectTier response: " + JSON.stringify(response));

      if (response.result === "success") {
        // getStatus();
        console.log(`Should be redirecting to: ${response.confirmationUrl}`);
        // redirect.dispatch(Redirect.Action.REMOTE, response.confirmationUrl);
        window.open(response.confirmationUrl, "_top");
      } else {
        setPostingTier(null);
        // setPostingFailed(true);
      }
    } catch (error) {
      setPostingTier(null);
      // setPostingFailed(true);
    }
  };

  const cancelSubscription = async (tier) => {
    try {
      setPostingTier(tier);

      // Update product on database with the new image URL
      const responseRaw = await authenticatedFetch(
        app,
        `/api/settings/cancelActiveSubscription`,
        {
          method: "POST",
          body: JSON.stringify({}),
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      );
      const response = await responseRaw.json();
      console.log("cancelSubscription response: " + JSON.stringify(response));

      setPostingTier(null);
      if (response.result === "success") {
        setSubscriptionInfo(null); // TODO: sort this out properly. silly having this global state plus a separate and incompatible internal state here, line below
        fetchSubscriptionTier();
      } else {
        // setPostingFailed(true);
      }
    } catch (error) {
      setPostingTier(null);
      // setPostingFailed(true);
    }
  };

  const subscriptionOptions = [
    {
      tier: "FREE",
      title: "Free",
      displayPrice: null,
      removeWatermark: false,
      benefit: (
        <>
          Automated watermarked mockups on <b>10 products</b>
        </>
      ),
    },
    {
      tier: "TIER_MANUAL",
      title: "Manual Unlimited",
      displayPrice: "$4.99",
      removeWatermark: true,
      benefit: (
        <>
          Unlimited manual mockups with <b>no watermark</b>
        </>
      ),
    },
    {
      tier: "TIER_500",
      title: "Automate 500",
      displayPrice: "$49",
      removeWatermark: true,
      benefit: (
        <>
          Automated mockups on <b>500 products</b>
        </>
      ),
    },
    {
      tier: "TIER_1000",
      title: "Automate 1000",
      displayPrice: "$79",
      removeWatermark: true,
      benefit: (
        <>
          Automated mockups on <b>1000 products</b>
        </>
      ),
    },
    {
      tier: "TIER_2000",
      title: "Automate 2000",
      displayPrice: "$125",
      removeWatermark: true,
      benefit: (
        <>
          Automated mockups on <b>2000 products</b>
        </>
      ),
    },
    // {
    //   tier: "TIER_5000",
    //   title: "Automate 5000",
    //   displayPrice: "$249",
    //   removeWatermark: true,
    //   benefit: (
    //     <>
    //       Automated mockups on <b>5000 products</b>
    //     </>
    //   ),
    // },
  ];
  const activeTierIndex = subscriptionOptions.findIndex(
    (e) => e.tier === currentSubscriptionTier
  );

  function SubscriptionOptionRow({
    isActive,
    title,
    subtitle,
    price,
    isUpgrade,
    action,
    posting,
    disabled,
  }) {
    return (
      <Card.Section subdued={isActive}>
        <Stack alignment="center" spacing="extraLoose">
          <Stack vertical spacing="extraTight">
            <Stack.Item>
              <div style={{ fontSize: "12pt", fontWeight: 500 }}>{title}</div>
            </Stack.Item>
            <Stack.Item>
              <div style={{ fontSize: "11pt", color: "rgba(0, 0, 0, 0.5)" }}>
                {subtitle}
              </div>
            </Stack.Item>
          </Stack>
          <Stack.Item fill />
          <Stack.Item>
            {price && (
              <div
                style={{
                  fontSize: "12pt",
                  color:
                    isActive || !isUpgrade
                      ? "rgba(0, 0, 0, 0.3)"
                      : "rgba(0, 0, 0, 0.6)",
                }}
              >
                <div style={{ display: "inline", fontWeight: 600 }}>
                  {price}
                </div>{" "}
                / month
              </div>
            )}
          </Stack.Item>
          {!isActive && price && isUpgrade && (
            <Button
              variant="primary"
              loading={posting}
              disabled={disabled}
              onClick={() => action()}
            >
              Upgrade
            </Button>
          )}
          {!isActive && price && !isUpgrade && (
            <Button
              variant="tertiary"
              loading={posting}
              disabled={disabled}
              onClick={() => action()}
            >
              Downgrade
            </Button>
          )}
          {isActive && !price && (
            <div
              style={{
                fontSize: "10pt",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 0.35)",
                marginRight: "0.4em",
              }}
            >
              Current Tier
            </div>
          )}
          {isActive && price && (
            <Button
              variant="tertiary"
              tone="critical"
              loading={posting}
              disabled={disabled}
              onClick={() => action()}
            >
              Cancel Subscription
            </Button>
          )}
        </Stack>
      </Card.Section>
    );
  }

  return (
    <Page
      backAction={{
        content: "Back",
        onAction: () => {
          setManageSubscriptionPageActive(false);
        },
      }}
      title="Manage Subscription"
    >
      <Card>
        {!isLoading && currentSubscriptionTier ? (
          <>
            {subscriptionOptions.map((option, i) => {
              if (
                option.tier !== "TIER_MANUAL" ||
                currentSubscriptionTier === "TIER_MANUAL"
              ) {
                return (
                  <SubscriptionOptionRow
                    key={option.tier}
                    isActive={option.tier === currentSubscriptionTier}
                    title={option.title}
                    subtitle={option.benefit}
                    price={option.displayPrice}
                    isUpgrade={i > activeTierIndex}
                    action={() => {
                      if (option.tier === currentSubscriptionTier) {
                        cancelSubscription(option.title);
                      } else {
                        selectTier(option.title);
                      }
                    }}
                    disabled={
                      postingTier !== null && postingTier !== option.title
                    }
                    posting={option.title === postingTier}
                  />
                );
              }
              return null;
            })}
          </>
        ) : (
          <Card.Section>
            <Stack distribution="center">
              <div style={{ height: "289px" }}>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Spinner size="large" />
              </div>
            </Stack>
          </Card.Section>
        )}
      </Card>
      {/* <br />
      <Banner title="Licensing of generated images">
        Automatically generated mockup images may only be used commercially
        while you have a subscription that covers them
      </Banner> */}
    </Page>
  );
}

export default ManageSubscriptionPage;
