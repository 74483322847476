import React from "react";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  className?: React.ComponentProps<"div">["className"];
}

function PageHeading(props: Props) {
  return (
    <div className={`text-lg font-bold ` + props.className}>
      {props.children}
    </div>
  );
}

function AreaHeading(props: Props) {
  return (
    <div className={`text-base font-semibold ` + props.className}>
      {props.children}
    </div>
  );
}

function SectionHeading(props: Props) {
  return (
    <div className={`text-sm font-semibold ` + props.className}>
      {props.children}
    </div>
  );
}

function SubSectionHeading(props: Props) {
  return (
    <div className={`text-[13px] font-semibold ` + props.className}>
      {props.children}
    </div>
  );
}

export { PageHeading, AreaHeading, SectionHeading, SubSectionHeading };
